// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import { I18n } from '@aws-amplify/core';

/* -----------------UI--------------- */
import { withStyles } from '@material-ui/core/styles';
import { Box, Container, Typography } from '@material-ui/core';

/* -----------------Assets--------------- */
import ErrorImage401 from '@images/illustrations/401.svg';
import ErrorImage404 from '@images/illustrations/404.svg';

/* -----------------Child components--------------- */
import DashboardCardEmpty from '../dashboard/DashboardCardEmpty';

/* -----------------Styles--------------- */
const styles = () => ({
  image: {
    width: '100%',
    maxWidth: 400,
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  image: string,
};

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  statusCode: number
}

/* -----------------Component--------------- */
const ErrorComponent = (props: Props) => {
  const {
    statusCode,
    classes,
  } = props;

  const subtitle = 'Looking for study content?';
  const cardTypes = ['create', 'find', 'purchase'];
  const title = statusCode === 401 ? 'This page is off-limits' : 'Whoops! Page not found';

  const renderErrorImage = () => {
    switch (statusCode) {
      case 401:
        return (
          <img
            src={ErrorImage401}
            alt={I18n.get('This page is off-limits')}
            className={classes.image}
          />
        );
      case 404:
        return (
          <img
            src={ErrorImage404}
            alt={I18n.get('Page not found')}
            className={classes.image}
          />
        );
      default:
        return (
          <img
            src={ErrorImage404}
            alt={I18n.get('Page not found')}
            className={classes.image}
          />
        );
    }
  };

  return (
    <Container maxWidth="xl">
      <Box
        width="100%"
        pt={6}
        pb={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box py={2}>{renderErrorImage()}</Box>
        <Box py={2}>
          <Typography variant="h4">{I18n.get(title)}</Typography>
        </Box>
        <Box py={2}>
          <Typography variant="h6">{I18n.get(subtitle)}</Typography>
        </Box>
        <Box
          py={1}
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
        >
          {cardTypes.map(type => (
            <Box key={type} width="100%" maxWidth={360} py={1} px={1}>
              <DashboardCardEmpty key={type} variant={type} card />
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(ErrorComponent);
