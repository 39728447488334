// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import clsx from 'clsx';

/* -----------------UI--------------- */
import Box from '@material-ui/core/Box';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

/* -----------------Assets--------------- */
import Find from '@images/illustrations/undraw_experts3_3njd_sec.svg';
import Create from '@images/illustrations/undraw_notes1_cf55_sec.svg';
import Calendar from '@images/illustrations/calendar.svg';
import Browse from '@images/illustrations/browse.svg';
import Folder from '@images/illustrations/folder.svg';

/* -----------------Types--------------- */
import type {
  DashboardCardVariants,
} from 'common/types';

/* -----------------Child components--------------- */
import Link from '../common/link';

/* -----------------Styles--------------- */
const styles = theme => ({
  transparentBackground: {},
  card: {
    width: '100%',
    '&$transparentBackground': {
      marginBottom: theme.spacing(2),
      background: 'transparent',
      borderWidth: 4,
      borderStyle: 'dashed',
      borderColor: theme.palette.secondary.main,
    },
  },
  image: {
    height: 96,
    marginBottom: theme.spacing(2),
  },
  buttonLabel: {
    color: theme.palette.secondary.dark,
    fontWeight: theme.fontWeights.semiBold,
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  card: string,
  image: string,
  buttonLabel: string,
  transparentBackground: string,
  link: string,
};

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
  variant: DashboardCardVariants,
  card: ?Boolean,
  handleAction?: (type: string) => void
};

/* -----------------Component--------------- */
const DashboardCardEmpty = (props: Props) => {
  const {
    classes,
    variant,
    card,
    handleAction,
  } = props;

  const handleClick = (event) => {
    event.preventDefault();
    if (handleAction) {
      handleAction(variant);
    }
  };

  const variants = {
    studyset: {
      image: Create,
      cta: 'Create study set',
    },
    folder: {
      image: Folder,
      cta: 'Create subfolder',
    },
    browse: {
      image: Browse,
      cta: 'Browse',
    },
    create: {
      image: Create,
      path: '/create/studyset',
      cta: 'Create flashcards',
    },
    find: {
      image: Find,
      path: '/topic',
      cta: 'Study free community content',
    },
    purchase: {
      image: '/static/illustrations/studypacks.png',
      path: '/store',
      cta: 'Get premium study-packs',
    },
    schedule: {
      image: Calendar,
      cta: 'Automate my study schedule',
      path: '/create/schedule',
    },
  };

  if (!variant || !variants[`${variant}`]) {
    return null;
  }

  const {
    image,
    path,
    cta,
  } = variants[`${variant}`];

  const elevation = card ? 2 : 0;

  const renderChildren = () => (
    <CardActionArea>
      <Box
        py={3.25}
        px={2}
        width="100%"
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img src={image} alt="" className={classes.image} />
        <Typography
          variant="button"
          color="secondary"
        >
          {cta}
        </Typography>
      </Box>
    </CardActionArea>
  );
  return (
    <>
      <Card
        elevation={elevation}
        classes={{ root: clsx(classes.card, card ? null : classes.transparentBackground) }}
        onClick={handleClick}
      >
        {path ? (
          <Link
            href={path}
            hrefAs={path}
            prefetch={false}
            className={classes.link}
          >
            {renderChildren()}

          </Link>
        ) : renderChildren()}

      </Card>
    </>
  );
};

export default withStyles(styles)(DashboardCardEmpty);
